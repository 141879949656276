import React, { useEffect, useState } from 'react';
import { Grid2 as Grid, TextField, Button, Divider } from '@mui/material';
import Modal from '../Modal';
import Show from './AuthComponents/Show';

const SomaFilter = (props) => {
  const [store, setStore] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.returnOne) {
      setStore({})
    }
    return () => setStore({})
  }, [props.returnOne, open])

  return (
    <div>
      <Modal actionName='REFINAR' modalName='FILTROS' open={open} onClose={() => setOpen(false)} action={() => { props.updateFilter(store); setOpen(false) }} >
        <Grid container direction='row' spacing={3} fullwidth="true">
          {props.filterFields.map((field, i) => {
            const updateStore = (val) => {
              setStore((st) => ({ ...st, [field.propName]: field.format ? field.format(val) : val }));
            }
            return (
              <Show to={field.show?.filter} key={`sf-${i}`}>
                <Grid item sx={{ width: { xs: "100%", md: "auto" }, maxWidth: {md: '290px'}, margin: { xs: "2% 0", md: "2% 25px" }, backgroundColor: 'rgba(255, 255, 255, 0.22)' }}>
                  {(field.component ?
                    <field.component
                      data-testid={field.propName}
                      label={field.propText}
                      {...field.componentProps}
                      onChange={updateStore}
                      isOnlyDate={field?.isOnlyDate}
                    />
                    :
                    <TextField
                      data-testid={field.propName}
                      label={field.propText}
                      slotProps={{ inputLabel: { shrink: true } }}
                      variant="standard"
                      fullWidth
                      sx={{ width: { xs: "100%", md: "120px"} }}
                      {...field.componentProps}
                      onChange={(e) => { updateStore(e.target.value); }}
                    />)}
                </Grid>
              </Show>
            )
          })}
        </Grid>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      </Modal>
      <Grid container justifyContent="flex-end">
        <Button data-testid="Button" variant="contained" fullWidth onClick={() => { open ? props.updateFilter(store) : setOpen(true) }} style={{ margin: "5px 0", width: 83, height: 32, border: '4px solid #CFBCAD', backgroundColor: "#CFBCAD", color: 'white', fontWeight: 600, fontFamily: 'flama-basic', fontSize: 14 }}> FILTRAR </Button>
      </Grid>
    </div>
  )
}
export default SomaFilter;