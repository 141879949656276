import React from "react";
import { useLocation } from "react-router-dom";

import FullPage from "../../components/FullPage";
import BemVindo from "../../components/BemVindo";
import Impersonate from "./Section/Impersonate";
import MinhasVendas from "./Section/MinhasVendas";
import UpdateCode from "./Section/UpdateCode/index";
import BarCode from "./Section/BarCode";
import CadastroAfiliado from "./Section/CadastroAfiliado";
import Divergencias from "./Section/Divergencias";
import VendasMarca from "./Section/VendasMarca";
import usePermission from "../../Hooks/usePermission";

const AdminPage = (props) => {
  const { getShowTo } = usePermission();
  const location = useLocation();
  let path = location.pathname

  let routes = [
    //{ route: "fat-realizados", text: "FATURAMENTOS REALIZADOS", component: FatRealizados },
    //{ route: "curva-fat", text: "CURVA DE FATURAMENTO", component: CurvaFat },
    {
      route: "impersonate",
      text: "IMPERSONATE",
      component: Impersonate,
      show: getShowTo("IMPERSONATE")
    },
    {
      route: "minhas-vendas-secreto",
      text: "MINHAS VENDAS",
      component: MinhasVendas,
      show: getShowTo("MINHAS_VENDAS")
    },
    {
      route: "divergencias",
      text: "DIVERGENCIAS",
      component: Divergencias,
      show: getShowTo("DIVERGENCIAS")
    },
    {
      route: "update-code",
      text: "Atualizar código",
      component: UpdateCode,
      show: getShowTo("ATUALIZAR_CODIGO")
    },
    {
      route: "barcode",
      text: "Codigo de Barras",
      component: BarCode,
      show: getShowTo("CODIGO_DE_BARRAS")
    },
    {
      route: "cadastro-afiliado",
      text: "Cadastro Afiliado",
      component: CadastroAfiliado,
      show: getShowTo("CADASTRO_AFILIADO")
    },
    {
      route: "vendas-marcas",
      text: "Vendas marcas",
      component: VendasMarca,
      show: getShowTo("VENDAS_MARCAS")
    },
  ];

  let config = {
    parentUrl: path,
    parentName: "ADMIN",
    parentComponent: BemVindo,
    routes: routes,
  };

  return <FullPage {...config} />;
};

export default AdminPage;
