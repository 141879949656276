import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

import { v4 } from 'uuid'

import useAffiliate from "../../../Hooks/AffiliateContext";

import HomeIcon from "../../../assets/images/icons/home_black.svg";
import FinanceiroIcon from "../../../assets/images/icons/cartao.svg";
import ShowroomIcon from "../../../assets/images/icons/caixa.svg";
import AfiliadosIcon from "../../../assets/images/icons/team.svg";
import MaterialIcon from "../../../assets/images/icons/book.svg";
import iconMaisAtacado from "../../../assets/images/logos/logoAtacado.svg";

import SideMenuListItem from "../SideMenuListItem/SideMenuListItem";

import "./SideMenuListItems.css";
import usePermission from "../../../Hooks/usePermission";

const isDefined = (value) => value !== undefined && value !== null;

const SideMenuListItems = (props) => {
  const { getShowTo } = usePermission();
  const { acceptedNewContract, affiliate } = useAffiliate();
  const { state, setState, isMenuVisible, shouldShow } = props;

  const menuConfigItems = useMemo(() => {
    return [
      {
        title: "admin",
        onClick: () => setState("admin"),
        show: getShowTo("ADMIN"),
        open: state.admin,
        items: [
          { name: "IMPERSONATE", url: "/Admin/impersonate", showTo: getShowTo("IMPERSONATE") },
          { name: "MINHAS VENDAS", url: "/Admin/minhas-vendas-secreto", showTo: getShowTo("MINHAS_VENDAS") },
          { name: "DIVERGENCIAS", url: "/Admin/divergencias", showTo: getShowTo("DIVERGENCIAS") },
          { name: "ATUALIZAR CÓDIGO", url: "/Admin/update-code", showTo: getShowTo("ATUALIZAR_CODIGO") },
          { name: "CODIGO DE BARRAS ", url: "/Admin/barcode", showTo: getShowTo("CODIGO_DE_BARRAS") },
          { name: "CADASTRO AFILIADO", url: "/Admin/cadastro-afiliado", showTo: getShowTo("CADASTRO_AFILIADO") },
          { name: "VENDAS MARCAS", url: "/Admin/vendas-marcas", showTo: getShowTo("VENDAS_MARCAS") },
        ],
      },
      {
        title: "financeiro",
        onClick: () => setState("financeiro"),
        show: getShowTo("FINANCEIRO"),
        open: state.financeiro,
        items: [
          { name: "FATURAS", url: "/Financeiro/faturas", showTo: getShowTo("FATURAS") },
          { name: "NEGOCIA+", url: "/Financeiro/negociacoes", showTo: getShowTo("NEGOCIA_MAIS") },
          { name: "DESCONTOS CONCEDIDOS", url: "/Financeiro/descontos", showTo: getShowTo("DESCONTOS_CONCEDIDOS") },
        ],
      },
      {
        title: "showroom digital",
        onClick: () => setState("comercial"),
        show: getShowTo("COMERCIAL"),
        open: state.comercial,
        items: [
          {
            name: "SEGUNDA VIA DE NOTA FISCAL",
            url: "/Comercial/seg-via-nota-fiscal",
            showTo: getShowTo("SEGUNDA_VIA_DE_NOTA_FISCAL")
          },
          { name: "MEUS PEDIDOS", url: "/Comercial/meus-pedidos", showTo: getShowTo("MEUS_PEDIDOS") },
          { name: "BLOQUEIOS", url: "/Comercial/bloqueios", showTo: getShowTo("BLOQUEIOS") },
          { name: "RECEBIMENTO", url: "/Comercial/recebimento", showTo: getShowTo("RECEBIMENTO") },
          { name: "METAS", url: "/Comercial/metas", showTo: getShowTo("METAS") },
        ],
      },
      {
        title: "afiliados",
        onClick: () => setState("afiliado"),
        show: getShowTo("AFILIADOS"),
        open: state.afiliado,
        items: [
          {
            name: "DADOS CADASTRAIS",
            url: "/Afiliado/meu-cadastro",
            showTo: isDefined(affiliate) && getShowTo("DADOS_CADASTRAIS"),
          },
          {
            name: "MEUS VENDEDORES",
            url: "/Afiliado/afiliados",
            showTo: isDefined(affiliate) && acceptedNewContract && getShowTo("MEUS_VENDEDORES")
          },
          {
            name: "MINHAS VENDAS",
            url: "/Afiliado/minhas-vendas",
            showTo: isDefined(affiliate) && getShowTo("MINHAS_VENDAS_AFILIADO"),
          },
          {
            name: "VENDAS AFILIADOS",
            url: "/Afiliado/visao-vendas",
            showTo: getShowTo("VENDAS_AFILIADOS"),
          },
          {
            name: "AFILIAR",
            url: "/Afiliado/cadastro",
            showTo: !isDefined(affiliate) && getShowTo("AFILIAR"),
          },
        ],
      },
      {
        title: "conteúdos",
        onClick: () => setState("material"),
        show: getShowTo("CONTEUDOS"),
        open: state.material,
        items: [
          {
            name: "materiais de prospecção",
            url: "/Material/saiba-mais",
            showTo: getShowTo("MATERIAIS_DE_PROSPECCAO")
          },
          {
            name: "animale",
            url: "/Material/animale",
            showTo: getShowTo("ANIMALE")
          },
          {
            name: "animale jeans",
            url: "/Material/animale-jeans",
            showTo: getShowTo("ANIMALE_JEANS")
          },
          {
            name: "fabula",
            url: "/Material/fabula",
            showTo: getShowTo("FABULA")
          },
          {
            name: "farm",
            url: "/Material/farm",
            showTo: getShowTo("FARM")
          },
          {
            name: "maria filo",
            url: "/Material/maria-filo",
            showTo: getShowTo("MARIA_FILO")
          },
          {
            name: "nv",
            url: "/Material/nv",
            showTo: getShowTo("NV")
          },
        ],
      },
    ];
  }, [getShowTo, state.admin, state.financeiro, state.comercial, state.afiliado, state.material, affiliate, acceptedNewContract, setState]);

  const menuConfigItemsIcons = useMemo(() => [
    {
      to: "/",
      icon: HomeIcon,
      className: `image__Item ${Object.values(state).every((s) => !s) && "image__activedhomeItem--conteiner"}`,
      label: "casa",
      show: true
    },
    {
      to: "/Financeiro/faturas",
      icon: FinanceiroIcon,
      className: `image__Item ${state.financeiro && "image__activedhomeItem--conteiner"}`,
      label: "faturas",
      show: getShowTo("FINANCEIRO")
    },
    {
      to: "/Comercial/seg-via-nota-fiscal",
      icon: ShowroomIcon,
      className: `image__Item ${state.comercial && "image__activedhomeItem--conteiner"}`,
      label: "sacolas de compras",
      show: getShowTo("COMERCIAL")
    },
    {
      to: "/Afiliado/cadastro",
      icon: AfiliadosIcon,
      className: `image__Item ${state.afiliado && "image__activedhomeItem--conteiner"}`,
      label: "pessoas",
      show: getShowTo("AFILIADOS")
    },
    {
      to: "/Material/saiba-mais",
      icon: MaterialIcon,
      className: `image__Item ${state.material && "image__activedhomeItem--conteiner"}`,
      label: "livro",
      show: getShowTo("CONTEUDOS")
    },
  ], [state, getShowTo]);

  const renderMenuItem = ({ to, icon, className, label, show }) => (
    show && (
      <li className="li__Imagem--item" key={v4()}>
        <NavLink exact="true" to={to} className={className}>
          <img src={icon} alt={`Ícone de ${label}`} />
        </NavLink>
      </li>
    )
  );

  return (
    shouldShow &&
    (
      isMenuVisible ?
        <ul className="ul__SideMenuListItems--conteiner">
          <li className="li__logoatacado--conteiner">
            <div>
              <img src={iconMaisAtacado} alt="Logo Mais Atacado" />
            </div>
          </li>
          <li className="li__homeItem--conteiner">
            <NavLink
              exact="true"
              to="/"
              className="a__homeItem--conteiner"
              activeclassname="a__activedhomeItem--conteiner"
            >
              <img src={HomeIcon} alt="Ícone de casa" />
              <span> home </span>
            </NavLink>
          </li>
          {menuConfigItems.map((item) => (
            <SideMenuListItem
              key={item.title}
              onClick={item.onClick}
              data={item}
              show={item.show}
              open={item.open}
            />
          ))}
        </ul>
        :
        <ul className="ul__Imagem--conteiner">
          {menuConfigItemsIcons.map(renderMenuItem)}
        </ul>
    )
  );
};

export default SideMenuListItems;
