import React from "react";
import { useLocation } from "react-router-dom";
import FullPage from "../../components/FullPage";
import BemVindo from "../../components/BemVindo";
import SaibaMais from "./Section/SaibaMais";
import usePermission from "../../Hooks/usePermission";

const MaterialPage = () => {
  const { getShowTo } = usePermission();
  const location = useLocation();
  let path = location.pathname

  let config = {
    parentUrl: path,
    parentName: "CONTEUDOS",
    parentComponent: BemVindo,
    routes: [
      {
        route: "saiba-mais",
        text: "SAIBA MAIS",
        component: SaibaMais,
        props: { category: 'material' },
        show: getShowTo("MATERIAIS_DE_PROSPECCAO")
      },
      {
        route: "animale",
        text: "animale",
        component: SaibaMais,
        props: { category: 'material_animale' },
        show: getShowTo("ANIMALE")
      },
      {
        route: "animale-jeans",
        text: "animale jeans",
        component: SaibaMais,
        props: { category: 'material_animale_jeans' },
        show: getShowTo("ANIMALE_JEANS")
      },
      {
        route: "fabula",
        text: "fabula",
        component: SaibaMais,
        props: { category: 'material_fabula' },
        show: getShowTo("FABULA")
      },
      {
        route: "farm",
        text: "farm",
        component: SaibaMais,
        props: { category: 'material_farm' },
        show: getShowTo("FARM")
      },
      {
        route: "maria-filo",
        text: "maria filo",
        component: SaibaMais,
        props: { category: 'material_maria_filo' },
        show: getShowTo("MARIA_FILO")
      },
      {
        route: "nv",
        text: "nv",
        component: SaibaMais,
        props: { category: 'material_nv' },
        show: getShowTo("NV")
      },
    ],
  };

  return <FullPage {...config} />;
};

export default MaterialPage;
