import React, { useState, useCallback } from "react";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";

import SomaStatus from "../../../../components/SomaStandard/SomaStatus";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";

import MaisAtacado from "../../../../Services/MaisAtacado/MaisAtacado";

import "./index.css";

const UpdateCode = () => {
  const [codeState, setCodeState] = useState({
    value: "",
    error: false,
    errorMessage: "",
    loadingStatus: "result",
  });

  const onSubmitHandler = useCallback(
    async (e) => {
      e.preventDefault();

      if (codeState.value.trim() === "") {
        return setCodeState((prevState) => ({
          ...prevState,
          error: true,
          errorMessage: "Obrigatorio",
        }));
      } else {
        setCodeState((prevState) => ({
          ...prevState,
          error: false,
          errorMessage: "",
        }));
      }

      try {
        setCodeState((prevState) => ({ ...prevState, loadingStatus: "wait" }));
        const res = await MaisAtacado.post("v1/UpdateCode/update-code", {
          code: codeState.value,
        });
        setCodeState((prevState) => ({
          ...prevState,
          loadingStatus: "result",
          value: "",
        }));
        if (res.data.brands.length > 0) {
          toast.success(
            `Código atualizado com sucesso para: ${res.data.brands.map(
              (item) => ` ${item}`
            )} !`,
            { theme: "colored" }
          );
        } else {
          toast.success("Código atualizado!", { theme: "colored" });
        }
      } catch (e) {
        setCodeState((prevState) => ({
          ...prevState,
          loadingStatus: "result",
          value: "",
        }));
        toast.error(e.response.data, { theme: "colored" });
      }
    },
    [codeState.value]
  );

  return (
      <SomaStatus status={codeState.loadingStatus}>
        <form
          className="form__updatecode--container"
          onSubmit={(e) => onSubmitHandler(e)}
        >
          <TextField
            error={codeState.error}
            helperText={codeState.errorMessage}
            label="Código"
            value={codeState.value}
            onChange={({ target: { value } }) =>
              setCodeState((prevState) => ({ ...prevState, value: value }))
            }
            InputLabelProps={{ shrink: true }}
            variant="standard"
            color="secondary"
            fullWidth
          />
          <div className="div__updatecodebutton--container">
            <PrimaryButton> Atualizar </PrimaryButton>
          </div>
        </form>
      </SomaStatus>
  );
};

export default UpdateCode;
