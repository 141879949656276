import React from "react";
import { useLocation } from "react-router-dom";
import FullPage from "../../components/FullPage";
import Descontos from "./Section/Descontos";
import SViaBoleto from "./Section/SViaBoleto";
import BemVindo from "../../components/BemVindo";
import NegociacoesAceitas from "./Section/Negociacoes";
import NegociacoesFaturas from "./Components/NegociacoesFaturas";
import usePermission from "../../Hooks/usePermission";

const FinanceiroPage = (props) => {
  const { getShowTo } = usePermission();
  const location = useLocation();
  let path = location.pathname

  let config = {
    parentUrl: path,
    parentName: "FINANCEIRO",
    parentComponent: BemVindo,
    routes: [
      {
        route: "faturas",
        text: "FATURAS",
        component: SViaBoleto,
        show: getShowTo("FATURAS")
      },
      {
        route: "negociar",
        text: "NEGOCIA+",
        component: NegociacoesFaturas,
        show: getShowTo("NEGOCIA_MAIS")
      },
      {
        route: "negociacoes",
        text: "NEGOCIA+",
        component: NegociacoesAceitas,
        show: getShowTo("NEGOCIA_MAIS")
      },
      {
        route: "descontos",
        text: "DESCONTOS CONCEDIDOS",
        component: Descontos,
        show: getShowTo("DESCONTOS_CONCEDIDOS")
      },
    ],
  };

  return <FullPage {...config} />;
};

export default FinanceiroPage;
