import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const DropdownMenu = (props) => {
    const [selectedValue, setSelectedValue] = useState('');

    return (
        <FormControl fullWidth>
            <InputLabel shrink={true} error={props.error} style={{backgroundColor: "#FFF", padding: "5px"}}>{props.label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={props.label}
                value={selectedValue}
                onChange={(e) => { props.onChange(e.target.value); setSelectedValue(e.target.value) }}
                sx={{ width: { xs: "100%", md: "140px"} }}
            >
                {props.options.map((o, i) => <MenuItem value={o.value} key={`dp-${i}`}>{o.text}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default DropdownMenu