import React, { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';

import SomaStatus from '../../../components/SomaStandard/SomaStatus'
import ItemsRow from '../../../components/ItemsRow/ItemsRow';

import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { authFetch } from '../../../helpers/authHelper';
import MaisAtacado from '../../../Services/MaisAtacado/MaisAtacado';
import handleApi from '../../../helpers/apiHelper';
import { trackingEvent } from '../../../Services/GoogleAnalytics';

const SaibaMais = (props) => {
    const [results, setResults] = useState([])
    const [status, setStatus] = useState([])

    const isLink = (m) => m.toLowerCase().startsWith('http')

    const downloadBtn = async (material) => {
        trackingEvent('abertura_materiais', `material: ${material.infos.name}`)
        let file
        if (isLink(material.idArquivo)) {
            return
        } else {
            file = await handleApi(() =>
                MaisAtacado.get(
                    `/v1/material/getFile?idArquivo=${encodeURI(material.idArquivo)}`,
                    { responseType: 'blob' }
                ))
            if (file.error) return

            file = URL.createObjectURL(file.data);
        }

        const a = document.createElement("a");
        a.href = file;
        a.download = material.idArquivo;
        a.click();
    }

    const openBtn = async (material) => {
        trackingEvent('abertura_materiais', `material: ${material.infos.name}`)
        let file
        if (isLink(material.idArquivo)) {
            file = material.idArquivo
        }
        else {
            file = await handleApi(() =>
                MaisAtacado.get(
                    `/v1/material/getFile?idArquivo=${encodeURI(material.idArquivo)}`,
                    { responseType: 'blob' }
                ))
            if (file.error) return
            file = URL.createObjectURL(await file.data);
        }

        const a = document.createElement("a");
        a.href = file;
        a.target = '_blank';
        a.click();
    }

    const rodape = () => {
        return <>
            <p style={{ fontSize: '12px' }}>
                <span style={{ marginRight: window.innerWidth > 480 ? '20px' : '0', display: window.innerWidth > 480 ? 'inline' : 'block' }}>
                    <EmailIcon fontSize='small' />
                    <a style={{ verticalAlign: 'super', marginLeft: '3px' }}
                        href="mailto:atendimentomultimarcas@somagrupo.com.br"
                        target='_blank'
                        rel="noreferrer">
                        atendimentomultimarcas@somagrupo.com.br
                    </a>
                </span>
                <span>
                    <WhatsAppIcon fontSize='small' />
                    <a style={{ verticalAlign: 'super', marginLeft: '3px' }}
                        href="https://api.whatsapp.com/send/?phone=552137337708&type=phone_number&app_absent=0"
                        target='_blank'
                        rel="noreferrer">
                        +55 21 3733-7708
                    </a>
                </span>
            </p >
        </>
    }

    useEffect(() => {
        setStatus('wait');
        (async () => {
            try {
                const compararDatas = (date) => differenceInDays(new Date(), new Date(date))

                const json = await (await authFetch(`/v1/material?category=${props.category}`)).json();

                Object.entries(json).forEach(([titulo]) => {
                    json[titulo].forEach((material, index) => {
                        json[titulo][index] = {
                            ...material,
                            rodape: rodape(),
                            tag: {
                                name: 'Novo',
                                color: '#FFD700',
                                Show: compararDatas(material.createdAt) >= 0 && compararDatas(material.createdAt) <= 7
                            },
                            leftBtn: {
                                onClick: () => openBtn(material),
                                name: 'ABRIR',
                                style: { backgroundColor: material.infos.thumb }
                            },
                            rightBtn: {
                                onClick: () => downloadBtn(material),
                                name: 'DOWNLOAD',
                                style: { color: material.infos.thumb, borderColor: material.infos.thumb, display: isLink(material.idArquivo) ? 'none' : ''}
                            }
                        }
                    })
                });

                setResults(json);
                setStatus(Object.keys(json)?.length ? 'result' : 'empty');
            } catch (err) {
                setResults(undefined);
                setStatus('error');
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.category]);

    return (
        <SomaStatus status={status}>
            {
                Object.entries(results).map(([key, value]) => {
                    return (<ItemsRow key={key} widthCard={184} title={key} items={value} />)
                })
            }
        </SomaStatus>
    )
}

export default SaibaMais;