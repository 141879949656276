import React from "react";
import { Routes, Route } from 'react-router-dom'
import PrivateRoute from "./components/SomaStandard/AuthComponents/PrivateRoute";
import Logoff from "./routes/Logoff";
import Home from "./routes/Home/Home";
import Financeiro from "./routes/Financeiro";
import Comercial from "./routes/Comercial";
import Afiliado from "./routes/Afiliado";
import Admin from "./routes/Admin";
import Login from "./routes/Login";
import LoginSomaAuth from "./routes/LoginSomaAuth";
import Auth from "./routes/Auth";
import Material from "./routes/Material";

const RoutesComponent = () => (
  <Routes>
    <Route exact="true" path="/Login" element={<Login />} />
    <Route exact="true" path="/Login-Soma-Auth" element={<LoginSomaAuth />} />
    <Route exact="true" path="/auth" element={<Auth />} />

    <Route exact="true" path="/Logoff" element={
      <PrivateRoute exact path="/Logoff">
        <Logoff />
      </PrivateRoute>
    } />

    <Route exact="true" index={true} path="/" element={
      <PrivateRoute exact path="/">
        <Home />
      </PrivateRoute>
    } />

    <Route exact="true" index={true} path="/Material/*" element={
      <PrivateRoute exact path="/Material">
        <Material />
      </PrivateRoute>
    } />

    <Route path="/Financeiro/*" element={
      <PrivateRoute path="/Financeiro">
        <Financeiro />
      </PrivateRoute>
    } />

    <Route path="/Comercial/*" element={
      <PrivateRoute path="/Comercial">
        <Comercial />
      </PrivateRoute>
    } />

    <Route path="/Afiliado/*" element={
      <PrivateRoute path="/Afiliado">
        <Afiliado />
      </PrivateRoute>
    } />

    <Route path="/Admin/*" element={
      <PrivateRoute path="/Admin">
        <Admin />
      </PrivateRoute>
    } />

  </Routes>
);

export default RoutesComponent;
