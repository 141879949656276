import React from "react";
import SomaForm from "../../../components/SomaStandard/SomaForm2";
import { useAuth } from "../../../Hooks/UserContext";
import SomaStatus from "../../../components/SomaStandard/SomaStatus";
import Dropdown from "../../../components/SomaStandard/FormComponents/Dropdown";

const columns = [
  {
    propName: "clifor",
    propText: "CLIFOR",
    validate: [{ test: (e) => e, message: "Obrigatorio" }],
  },
  {
    propName: "banco",
    propText: "BANCO",
    component: Dropdown,
    componentProps: {
      options: [
        { text: "SOMA", value: "SOMA" },
        { text: "Maria Filó", value: "MF" },
        { text: "Cris Barros", value: "CB" },
      ],
    },
    validate: [{ test: (e) => e, message: "Obrigatorio" }],
  },
];

const Impersonate = (props) => {
  const { impersonate } = useAuth();

  return (
      <SomaStatus status={impersonate.status === "pending" ? "wait" : "result"}>
        <SomaForm
          columns={columns}
          submitText={"IMPERSONATE"}
          onSubmit={(e) => impersonate.dispatch(e.clifor, e.banco)}
          preload={{ banco: "SOMA" }}
          onCancel={() => impersonate.dispatch("", "")}
          cancelText={"REVERT"}
        />
      </SomaStatus>
  );
};

export default Impersonate;
