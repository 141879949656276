import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

const ToFrom = (props) => {
    const [filter, setFilter] = useState({})
    const { onChange, label, isOnlyDate, minDate, maxDate } = props;

    useEffect(() => {
        onChange(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    return <div style={{ display: "flex" }}>
        {
            props.component ?
                <>
                    <props.component minDate={minDate} maxDate={maxDate} isOnlyDate={isOnlyDate} label={label + " de"} onChange={val => setFilter({ ...filter, gte: val })} />
                    <props.component minDate={minDate} maxDate={maxDate} isOnlyDate={isOnlyDate} endDate={true} label="Até" onChange={val => setFilter({ ...filter, lte: val })} />
                </>
                :
                <>
                    <TextField data-testid="textGte" label={label + " de"} variant="standard" color="secondary" slotProps={{ inputLabel: { shrink: true }}} sx={{ width: { xs: "100%", md: "100px"} }} onChange={e => setFilter({ ...filter, gte: e.target.value })} />
                    <TextField data-testid="textLte" label="Até" variant="standard" color="secondary" slotProps={{ inputLabel: { shrink: true }}} sx={{ width: { xs: "100%", md: "100px"} }} onChange={e => setFilter({ ...filter, lte: e.target.value })} />
                </>
        }

    </div>
}
export default ToFrom

