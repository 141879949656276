import useUser from "../Hooks/UserContext";

const usePermission = () => {
  const user = useUser();
  const permissions = user.permissions || [];

  const getShowTo = (permissionName) => {
    if (!(permissionName in permissions)) return false; 
    return permissions[permissionName]
  };

  return { getShowTo };
};

export default usePermission;
